import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import Typography from "@material-ui/core/Typography"
import "../css/index.css"

import SEO from "../components/common/seo"

const styles = theme => ({
  root: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#2B344B",
  },

  container: {
    width: "100%",
    height: "83vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  content: {
    maxWidth: "300px",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  logoContainer: {
    display: "flex",
    alignItems: "flex-end",
    flex: 1,
    position: "relative",
  },

  logoContainerOuter: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto",
    width: "calc(100% - 80px)",
    height: "70px",
  },

  logoDesc: {
    margin: "auto 0",
    fontSize: "13px",
    color: "#FFFFFF",
    whiteSpace: "nowrap",
    paddingTop: "4px",
    position: "absolute",
    right: "-115px",
    bottom: "9px",

    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "10px",
      right: "-90px",
      bottom: "12px",
    },

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  logoBottomDesc: {
    margin: "auto 0",
    fontSize: "10px",
    color: "#FFFFFF",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    paddingLeft: "40px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
    },
  },

  desert: {
    position: "fixed",
    bottom: 0,
    width: "100%",
  },

  moon: {
    position: "fixed",
    top: 80,
    right: 80,
    width: "140px",
  },

  logo: {
    marginRight: "8px",
  },

  button: {
    position: "relative",
    background: "#1A65FF",
    marginTop: "40px",
    padding: "0 20px",
    borderRadius: "2px",
    fontSize: "15px",
    cursor: "pointer",
    transition: "all 120ms linear",
    textDecoration: "none",
    height: "38px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    width: "100%",
    opacity: 1,

    // production環境だとホバーしない限り画面が崩れっぱなしになるので一旦コメント
    // "&:hover": {
    //   background: "#1A65FF",
    //   opacity: 0.6,
    // },
  },

  title: {
    color: "#FFFFFF",
    textAlign: "center",
    paddingTop: "12px",
    fontWeight: "bold",
    margin: 0,
  },

  desc: {
    color: "#FFFFFF",
    paddingTop: "20px",
  },
})

const NotFoundPage = ({ classes }) => (
  <div className={classes.root}>
    <SEO title="404: Not found" />

    <div className={classes.logoContainerOuter}>
      <div className={classes.logoContainer}>
        <Link to="/">
          <picture>
            <source
              src={require("../images/KL_logo.webp")}
              width={185}
              height={32}
              type="image/webp"
            />
            <img
              src={require("../images/logo_dark.png")}
              width={185}
              height={32}
              className={classes.logo}
              alt="ロゴ"
            />
          </picture>
        </Link>
        <Typography className={classes.logoDesc}>
          決裁者データベース
        </Typography>
      </div>
      <Typography className={classes.logoBottomDesc}>
        大企業決裁者との商談獲得のためのレター送付ツール
      </Typography>
    </div>

    <img
      alt="moon.svg"
      src={require("../images/moon.svg")}
      className={classes.moon}
    />
    <img
      alt="desert.svg"
      src={require("../images/desert.svg")}
      className={classes.desert}
    />

    <div className={classes.container}>
      <div className={classes.content}>
        <h1 style={{ color: "#FFFFFF" }} class="heading-size-90">
          404
        </h1>
        <h4 className={classes.title}>お探しのページは見つかりませんでした…</h4>
        <p className={classes.desc}>
          入力したURLが間違っている可能性があります。ご確認の上、もう一度お試しください。
        </p>
        <Link to="/" className={classes.button}>
          ホームへ戻る
        </Link>
      </div>
    </div>
  </div>
)

NotFoundPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(NotFoundPage)
